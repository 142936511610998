<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row ">
        <div class="col-12">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Normatividad
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Normatividad
          </h2>

          <h4 class="color-institucional">
            Normatividad general
          </h4>
          <b-table-simple class="mb-4" hover small>
            <b-thead>
              <b-tr>
                <b-th>
                  Descripción
                </b-th>
                <b-th>
                  Enlace
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <tr>
                <td>
                  Constitución Política.
                </td>
                <td>
                  <a
                    href="https://norma.ine.mx/normatividad-del-instituto/vigente/normativo/constitucion-politica-de-los-estados-unidos-mexicanos"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Ley General de Instituciones y Procedimientos Electorales
                </td>
                <td>
                  <a
                    href="https://sidj.ine.mx/restWSsidj-nc/app/doc/787/20/1"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Ley General de Partidos Políticos
                </td>
                <td>
                  <a
                    href="https://sidj.ine.mx/restWSsidj-nc/app/doc/787/20/1"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Reglamento de Elecciones del INE
                </td>
                <td>
                  <a
                    href="https://www.ine.mx/reglamento-de-elecciones/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Acuerdo por el que se aprueba el Modelo de Operación del Voto
                  de las Personas en Prisión Preventiva, para el PEF 2023 – 2024.
                </td>
                <td>
                  <a
                    href="http://www.dof.gob.mx/2024/INE/CGext202102_3_ap_14.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Modelo de Operación del Voto de las Personas en Prisión Preventiva.
                </td>
                <td>
                  <a
                    href="https://sidj.ine.mx/restWSsidj-nc/app/doc/1319/20/1"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
            </b-tbody>
          </b-table-simple>

          <h4 class="color-institucional">
            PREP
          </h4>
          <b-table-simple class="mb-4" hover small>
            <b-thead>
              <b-tr>
                <b-th>
                  Descripción
                </b-th>
                <b-th>
                  Enlace
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <tr>
                <td>
                  Anexo 13 del Reglamento de Elecciones relativo a los Lineamientos del PREP.
                </td>
                <td>
                  <a
                    href="https://sidj.ine.mx/restWSsidj-nc/app/doc/692/20/1"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Anexo 18.5 del Reglamento de Elecciones relativo a la Estructura
                  de los archivos CSV para el tratamiento de la Base de datos
                  relativa al Sistema de PREP.
                </td>
                <td>
                  <a
                    href="https://sidj.ine.mx/restWSsidj-nc/app/doc/803/20/1"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Acuerdo por el que se aprueba: el Proceso Técnico Operativo y
                  Consideraciones Generales para la Operación del PREP para el PEF 2023- 2024.
                </td>
                <td>
                  <a
                    href="https://repositoriodocumental.ine.mx/xmlui/bitstream/handle/123456789/115123/CGor202010-28-ap-23.pdf?sequence=1&isAllowed=y"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Acuerdo por el que se determina la ubicación, instalación y se instruye
                  al seguimiento y supervisión de los CATD y de los CCV, y se aprueban los
                  Lineamientos a los que se deberán sujetar los Consejos Locales y Distritales
                  del INE para la ejecución de los simulacros y operación del PREP.
                </td>
                <td>
                  <a
                    href="https://repositoriodocumental.ine.mx/xmlui/bitstream/handle/123456789/115886/CG2ex202012-07-ap-7.pdf?sequence=1&isAllowed=y"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Lineamientos a los que se deberán sujetar los Consejos Locales y
                  Distritales delINE para la ejecución de los simulacros y
                  operación del PREP para el PEF 2023-2024.
                </td>
                <td>
                  <a
                    href="https://sidj.ine.mx/restWSsidj-nc/app/doc/1243/20/1"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Acuerdo por el que se Aprueban las Precisiones a la Estructura y Contenido
                  de los Campos de los Archivos .CSV para el Tratamiento de las Bases de
                  Datos Relativas al PREP con motivo de la implementación de la Urna
                  Electrónica en una parte de las Casillas Únicas.
                </td>
                <td>
                  <a
                    href="https://sidj.ine.mx/restWSsidj-nc/app/doc/1392/20/3"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Anexo 1
                </td>
                <td>
                  <a
                    href="https://sidj.ine.mx/restWSsidj-nc/app/doc/1392/20/1"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Lineamientos para la conformación de la Lista Nominal de Electores de
                  Personas que se encuentran en Prisión Preventiva para el PEF 2023-2024.
                </td>
                <td>
                  <a
                    href="https://repositoriodocumental.ine.mx/xmlui/bitstream/handle/123456789/117956/CGor202102-26-ap-23-Lineamientos.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
            </b-tbody>
          </b-table-simple>

          <h4 class="color-institucional">
            Cómputos
          </h4>
          <b-table-simple class="mb-4" hover small>
            <b-thead>
              <b-tr>
                <b-th>
                  Descripción
                </b-th>
                <b-th>
                  Enlace
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <tr>
                <td>
                  Lineamientos para la preparación y desarrollo
                  de los Cómputos Distritales PEF 2023-2024.
                </td>
                <td>
                  <a
                    href="https://repositoriodocumental.ine.mx/xmlui/bitstream/handle/123456789/116138/CGor202012-15-ap-20-a1.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['far', 'file-pdf']" class="fa-2x" />
                  </a>
                </td>
              </tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>

@media (max-width: 576px) {       
   .px-5{
    padding-left: 0px;
    
   }
    
}
</style>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  name: 'CentroDeAyuda',
  components: {
    Layout,
  },
};
</script>
